/**
 * 一件代发 财务-对账开票API
 */

import request from "@/utils/request";

/**
 * 待开订单表总金额
 * @param {*} params 
 * @returns 
 */
export function getWaitOrderTotalPrice (params) {
  return request({
    url: "seller/shops/shopReceipt/getWaitAccountOrderTotalPrice",
    method: "get",
    loading: false,
    params,
  });
}
/**
 * 待开订单表列表
 * @param {*} params 
 * @returns 
 */
export function getWaitAccountOrderList (params) {
  return request({
    url: "seller/shops/shopReceipt/getWaitAccountOrderList",
    method: "get",
    loading: false,
    params,
  })
}
/**
 * 待开订单表列表-查看详情
 * @param {*} params 
 * @returns 
 */
export function getAccountStatement (params) {
  return request({
    url: "seller/shops/shopReceipt/getAccountStatement",
    method: "get",
    loading: false,
    params,
  })
}
/**
 * 待开订单表列表-查看详情（统计数）
 * @param {*} params 
 * @returns 
 */
export function getAccountStatementTotal (params) {
  return request({
    url: "seller/shops/shopReceipt/getAccountStatementTotal",
    method: "get",
    loading: false,
    params,
  })
}
export function getShopInfo (shop_id) {
  return request({
    url: `seller/shops/shopReceiptHistory/getShopInfo/${shop_id}`,
    method: "get",
    loading: false
  })
}
